

@mixin h1() {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: normal;
    font-style: normal;
    font-family: $fontAlt;
}
@mixin h2() {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: normal;
    font-style: normal;
    font-family: $fontAlt;
}
@mixin h3() {
    font-size: 1.75rem;
    line-height: 1.2;
    font-weight: normal;
    font-style: normal;
    font-family: $fontAlt;
}
@mixin h4() {
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: normal;
    font-style: normal;
    font-family: $fontAlt;
}

@mixin s1() {
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: normal;
    font-style: normal;
    font-family: $font;
}

@mixin s2() {
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: normal;
    font-style: normal;
    font-family: $font;
}

@mixin t1() {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: normal;
    font-style: normal;
    font-family: $font;
}

@mixin t2() {
    font-size: 0.75rem;
    line-height: 1.2;
    font-weight: normal;
    font-style: normal;
    font-family: $font;
}
