@import "../../css/vars";
@import "../../css/typo";

.widgets {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

}

.widget {
    border: 1px solid $color_border;
    border-radius: 1rem;
    backdrop-filter: blur(10px);
    position: relative;
    box-shadow: $shadow_module;
    &.__loaded {
        &:after {
            opacity: 0;
        }
        .widget__inner {
            pointer-events: all;
            opacity: 1;
        }
    }
    &:after {
        content: '';
        background: url(../../../public/loader.svg) no-repeat center center;
        position: absolute;
        background-size: 4rem;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
        transition: .2s;
        pointer-events: none;;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: -1;
        background-color: $color_white;
        border-radius: 1rem;
        opacity: 0.5;
    }
    .widget__inner {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        opacity: 0;
        transition: .2s;
        pointer-events: none;
    }
    .widget__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .widget__label {
        @include h4();
    }
    .widget__actions {
        display: flex;
        gap: 0.25rem;
        margin-left: 1rem;
    }
}