@import "../../css/vars";
@import "../../css/typo";

.paginator {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    .paginator__list {
        display: flex;
        @include actions(0.25rem);
    }
    .paginator__page {
        padding: 0.5rem;
        @include t2();
        @include transition();
        border: 1px solid $color_border;
        cursor: pointer;
        min-width: 2rem;
        text-align: center;
        background-color: $color_white;
        line-height: 1;
        border-radius: 0.25rem;
        user-select: none;
        &:hover {
            background-color: $color_primary_hover;
        }
        &:active {
            background-color: $color_primary_active;
        }
        &.__active {
            background-color: $color_primary;
            color: $color_white_alw;

            &:hover {
                background-color: $color_primary_hover;
            }
            &:active {
                background-color: $color_primary_active;
            }
        }
        &.__divider {
            &:hover, &:active {
                background-color: $color_white;
            }
            cursor: default;
        }
    }
}
