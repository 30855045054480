@import "../../css/vars";
@import "../../css/typo";

.user {
    position: relative;
    display: flex;
    align-items: center;
    .user__image {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        border-radius: 2.5rem;
        border: 2px solid $color_primary;
        .image {
            border-radius: 2.5rem;
            overflow: hidden;
            border: 2px solid transparent;
            width: 100%;
            height: 100%;
            .image__svg {
                width: 100%;
                height: 100%;
                svg {
                    height: auto;
                }
            }
        }
    }
    .user__info {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
    }
    .user__name {
        @include t1();
        color: $color_black;
    }
    .user__post {
        @include t2();
        color: $color_gray;
    }
}