@import "../../css/vars";
@import "../../css/typo";

.tableList {
    .tableList__head {
        background-color: $color_white;
        padding: 0.25rem;
        margin-bottom: 1rem;
        border-radius: 0.75rem;
        box-shadow: $shadow_module;
    }
    .group {
        background-color: $color_white;
        box-shadow: $shadow_module;
        border-radius: 0.75rem;
        padding: 0.25rem;
        &:not(:first-child) {
            margin-top: 1rem;
        }
        .group__title {
            padding-top: 0.25rem;
            margin-bottom: 0.5rem;
            padding-left: 0.25rem;
            color: $color_black;
            @include t1();
            font-family: $fontAlt;
        }
        .group__body {
            border-radius: 0.75rem;
        }
    }
}

.tableList__loadMask {
    .tableList__head {
        min-height: 40px;
        @include loadMask();
    }
    .group {
        min-height: 80px;
        padding: 0.25rem;
        display: flex;
        flex-direction: column;
        @include loadMask();
        &:not(:first-child) {
            margin-top: 1rem;
        }
        .group__title {
            height: 20px;
            width: 30%;
            @include loadMask();
            margin-bottom: 0.5rem;
        }
        .group__body {
            @include loadMask();
            flex-grow: 1;
            width: 100%;
        }
    }
}
