@import "../../../css/vars";
@import "../../../css/typo";

.widget._turnover {
    flex-basis: 60%;
    @media(max-width: $tablet){
        flex-basis: 100%;
    }
    .widget__actions {
        width: 100px;
    }
}