@import "../../css/vars";
@import "../../css/typo";

.tile {
    &.__loaded {
        &:not(.__mask) {
            .tile__inner {
                border: none;
            }
        }
        .tile__inner {
            background: none;
        }
        .tile__body {
            opacity: 1;
            transition-delay: 0.4s;
        }
        .tile__loadMask {
            opacity: 0;
            pointer-events: none;
        }
        .tile__loader {
            opacity: 0;
        }
    }
    &.__needBack {
        .tile__inner {
            background-color: $color_white;
            box-shadow: $shadow_module;
        }
    }
    &.__highlight {
        .tile__inner {
            transition: 0s;
            opacity: 0;
        }
    }
    &:not(.__mask) {
        .tile__inner {
            border: 1px solid $color_border;
        }
    }
    .tileTitle {
        @include h2();
        margin-bottom: 2rem;
    }
    .tile__inner {
        position: relative;
        min-height: 332px;
        border-radius: 0.5rem;
        transition: 0.4s;
    }
    .tile__loadMask {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        opacity: 1;
        transition: 0.4s;
        bottom: 0;
        overflow: hidden;
        border-radius: 0.5rem;
    }
    .tile__loader {
        width: 4rem;
        height: 4rem;
        background: url(../../../public/loader.svg) no-repeat center center;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        pointer-events: none;
        transition: 0.2s;
        z-index: 10;
    }
    .tile__body {
        opacity: 0;
        transition: 0.4s;
    }
}
