@import "../../css/vars";
@import "../../css/typo";

.sidebar {
    border-right: 1px solid $color_border;
    max-width: $sidebar_width;
    min-width: $sidebar_width;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color_white;
    min-height: 100dvh;
    box-shadow: inset -4px 0 16px -8px $color_shadow;
    & + main {
        margin-left: $sidebar_width;
    }
    @media (max-width: $tablet) {
        right: 0;
        z-index: 120;
        max-width: 100vw;
        min-width: 320px;
        width: 40vw;
        box-shadow: inset 0 0 16px -8px $color_shadow;
        // box-shadow: none;
        border: none;
        transform: translateX(100%);
        transition: .4s;
        & + main {
            margin-left: 0;
        }
    }
    @media (max-width: $mobile) {
        width: 100%;
    }
    &.__active {
        transform: none;
    }
    .sidebar__inner {
        left: 0;
        width: $sidebar_width;
        top: 0;
        display: flex;
        flex-direction: column;
        @media (max-width: $tablet) {
            width: 100%;
        }
    }
    .sidebar__header {
        height: $header_height;
        display: flex;
        justify-content: center;
        padding: 16px;
        @media (max-width: $tablet) {
            justify-content: space-between;
        }
        .logo .image svg * {
            fill: $color_black;
            // .__theme_dark & {
        }
        .sidebar__header_actions {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            a {
                @include t1();
            }
        }
    }
    .sidebar__footer {
        display: flex;
        flex-direction: column;
        .sidebar__footer_row {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            @media (max-width: $tablet) {
                justify-content: space-between;
            }
            a {
                @include t1();
            }
        }
    }
    .sidebar__body {
        padding: 2rem 0;
    }
    .sidebar__nav {
        display: flex;
        flex-direction: column;
        .sidebar__item {
            display: flex;
            align-items: center;
            color: $color_black;
            @include t1();
            @include transition();
            padding: 0.75rem 1rem;
            line-height: 1;
            cursor: pointer;
            opacity: 1;
            user-select: none;
            &:hover {
                background-color: $color_primary_hover;
                .icon {
                    svg * {
                        transition: 0.05s;
                    }
                }
            }
            &:active {
                background-color: $color_primary_active;
                .icon {
                    svg * {
                        transition: 0.02s;
                    }
                }
            }
            &.__active {
                color: $color_white_alw;
                background-color: $color_primary;
                &:hover {
                    background-color: $color_primary_hover;
                }
                &:active {
                    background-color: $color_primary_active;
                }
                .icon {
                    svg * {
                        fill: $color_white;
                    }
                }
            }
            .icon {
                margin-right: 0.5rem;
                margin-top: -2px;
                svg * {
                    transition: 0.2s;
                }
            }
        }
    }
    ._version {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $color_gray;
        @include t2();
    }
}

.sidebar__shadow {
    position: fixed;
    left: 0;
    right: 0;
    top: $header_height;
    bottom: 0;
    // background: red;
    z-index: 110;
    backdrop-filter: blur(10px);
    // z-index: -2;
    pointer-events: none;
    opacity: 0;
    transition: .4s;
    &.__active {
        opacity: 1;
        pointer-events: all;
    }
}