@import "../../css/vars";
@import "../../css/typo";

.modal {
    position: fixed;
    z-index: 80;
    top: $header_height;
    left: $sidebar_width;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    margin: 0 !important;
    &.__global {
        top: 0;
        left: 0;
    }
    &.__active {
        pointer-events: all;
        .modal__shadow {
            opacity: 1;
        }
        .modal__inner {
            opacity: 1;
            transform: none;
        }
    }
    &.__scroll {
        .modal__scroller {
            @include scrollWithoutScroll();
            mask-image: linear-gradient(to bottom, transparent, black 1rem, black calc(100% - 1rem), transparent);
            max-height: 100%;
            padding: 1rem 0;
        }
    }
    &.__hidden_blur {

        .modal__shadow {
            background-color: transparent;
            backdrop-filter: blur(0);
        }
    }
    .modal__shadow {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $color_white_aplha;
        backdrop-filter: blur(10px);
        transition: 0.4s;
    }
    .modal__close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
            transform: rotate(90deg);
        }
        .icon {
            font-size: 1.5rem;
        }
    }
    .modal__inner {
        position: relative;
        padding: 2rem 2rem 2rem;
        border: 1px solid $color_border;
        box-shadow: $shadow_module;
        border-radius: 1rem;
        width: 50vw;
        max-width: calc(100% - 2rem);
        min-width: 320px;
        transition: 0.4s;
        opacity: 0;
        transform: scale(0.5);
        max-height: 80dvh;
        display: flex;
        background-color: $color_white;
        flex-direction: column;
    }
    .modal__scroller {
    }
    .modal__body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .modal__title {
        @include h3();
        margin-bottom: 1rem;
    }
}
