@import "../../css/vars";
@import "../../css/typo";

.page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 2rem;
    position: relative;
    min-height: calc(100dvh - #{$header_height});
    opacity: 0;
    transition: .4s;
    padding-bottom: 10rem;
    @media(max-width: $mobile){
        padding: 2rem 1rem;
    }
    &.__centered {
        align-items: center;
        justify-content: center;
    }
    &.__prepared {
        opacity: 1;
        .page__body {
        }
    }
    .page__header {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .page__title {
            @include h1();
            color: $color_black;
            .page__title_info {
                @include t2();
                color: $color_gray;
                margin-left: 1rem;
            }
        }
        .page__actions {

        }
    }
    .__searchForm {
        margin-bottom: 32px;

        .form__col.__asRow {

            @media (max-width: $mobile) {
                flex-direction: column;
            }
        }

        .btn {
            height: 100%;

            @media (max-width: $mobile) {
                width: 100%;
            }
        }
    }  
    .page__body {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        & > * {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}
.page__develop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 0;
    .page__develop__image {
        width: 20rem;
        max-width: 80%;
        margin-bottom: 2rem;
        filter: grayscale(1) sepia(1) saturate(3) hue-rotate(185deg)
    }
    .page__develop__text {
        @include h4();
        color: $color_gray;
    }
}

.page.dashboard {
    &.__prepared {
        &:before {
            opacity: 0.6;
            transition: 2s 1s;
        }
    }
    &:before {
        content: '';
        background: url(../../../public/assets/ref__dashboard_back.svg) no-repeat center center;
        background-size: 100vw;
        position: absolute;
        opacity: 0.6;
        filter: blur(10px);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: .2s;
        pointer-events: none;
    }
}