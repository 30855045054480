
.image {
    display: flex;
    .image__svg {
        display: flex;
        svg {
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
    img {
        max-width: 100%;
        height: auto;
    }
}