@import "../../css/vars";
@import "../../css/typo";


.notifBox {
    position: fixed;
    top: calc(#{$header_height} + 1rem);
    right: 2rem;
    z-index: 90;
    pointer-events: none;
    .notifBox__list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        transition: .2s;
        min-width: $sidebar_width;
        max-width: $sidebar_width;
    }
    .notifBox__item {
        box-shadow: $shadow_module;
        border: 1px solid $color_border;
        padding: 0.75rem 1rem;
        display: flex;
        flex-direction: column;
        transition: .2s;
        transform: translateY(calc(100% + 2rem));
        opacity: 0;
        border-radius: 0.5rem;
        background-color: $color_white;

        // height: 80px;
        
        &.__success {
            border-color: $color_green;
        }
        &.__warning {
            border-color: $color_red;
        }
        &.__inited {
            opacity: 1;
            transform: none;
        }
        &.__died {
            // background-color: red
            transform: translateY(-200%);
        }
        .notifBox__item_title {
            @include t1();
            font-family: $fontAlt;
            
        }
        .notifBox__item_text {
            @include t2();
            color: $color_gray;
            margin-top: 0.25rem
        }
    }
}
